import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 10px 0 0 0;
    li{
        font-weight: 400;
        width: 100%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Bigsub = styled.h2`
    font-weight: 700;
    font-size: 28px;
    color: var(--green-text);
`

const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "diensticonen/recycling.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        anotherImage: file(relativePath: { eq: "recycling/recycling.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        softdes: file(relativePath: { eq: "datavernietiging/softwarematige_dataverwijdering.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mechdes: file(relativePath: { eq: "datavernietiging/mechanische_dataverwijdering.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    `)
    const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />,<Img className={className} fluid={data.anotherImage.childImageSharp.fluid}/>, <Img className={className} fluid={data.softdes.childImageSharp.fluid} />, <Img className={className} fluid={data.mechdes.childImageSharp.fluid} />]
    return image[something];
    }


const StageCustomerServiceAgent = () => (
    
  <Layout>
    <SEO title="Vactures" />
    <PageHeader title="Vacatures">
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink href="/informatie" onClick={handleClick}>
            Informatie
          </Crumblink>
          <Crumblink href="/informatie/vacatures" onClick={handleClick}>
            Vacatures
          </Crumblink>
          <Crumblink aria-current="page" href="/informatie/vacatures/Stage Customer Service Agent" onClick={handleClick}>
            Stage Customer Service Agent
          </Crumblink>
        </Styledcrumbs>
    </PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                    <Paragraph>
                    <Bigsub>Stage Customer Service Agent</Bigsub>
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Paragraph>

                            <b>Op zoek naar een stage of bijverdienste als Customer Service Agent ?</b> 
                    
                    </Paragraph>
                    <Paragraph>

                            Een vriendelijk en vrolijk persoon ! Is dit de omschrijving die op jou van toepassing is? Dan maken we graag kennis met jou !

                    
                    </Paragraph>
                    <Paragraph>
            
                            Wij zijn per direct op zoek naar een stagiaire of student die onze afdeling binnendienst wil versterken. Zoek jij een leuke en stageplaats waar je praktijkervaring kunt combineren met een (afstudeer)onderzoek ? Of ben je naast je opleiding op zoek naar een leuke bijverdienste ? Dan zijn wij op zoek naar jou !
            
                    </Paragraph>
                    <Paragraph>

                            Je stage en werkzaamheden zullen gericht zijn op het contact leggen met onze bestaande en toekomstige relaties. Het betreft voornamelijk uitgaande gesprekken, maar ook de beantwoording van e-mails. Naast een prettige telefoonstem is het belangrijk dat je de Nederlandse taal goed machtig bent in zowel woord als geschrift. Daarnaast kun je ook brede ervaring opdoen met administratieve werkzaamheden op de afdeling customer service.

                    </Paragraph>
                    <Paragraph>

                            Ons bedrijf is gespecialiseerd in de opkoop, inname en verwerking van ICT apparatuur en smartphones. Je hoeft geen specifieke kennis te hebben van ICT hardware, hiervoor verzorgen wij een cursus. Green Digital is een organisatie die veel waarde hecht aan een aangename werkomgeving. Daarnaast is het onze missie om een bijdrage te leveren aan de samenleving en het behoud van natuur en milieu: wij proberen de CO2 footprint zo veel mogelijk te reduceren door apparatuur geschikt te maken voor hergebruik en recycling.

                    </Paragraph>
                    <Paragraph>

                            Wij zoeken een assertieve HBO student(e) (bijv 3e of 4e jaars) die op prettige en zakelijke wijze kan communiceren. Stuur dan een beknopte motivatiebrief en CV.

                    </Paragraph>      
                    <Paragraph>
                    <Paragraph />
                    <Paragraph />
                        <b>Naast een gezellige informele werksfeer en fijne collega’s bieden wij:</b><br/>
                      <Itemlist>
                                  <li><StyledLiIcon icon={faDotCircle} /> Werken bij een "groen" en groeiend bedrijf met volop ontwikkelingsmogelijkheden;<br/></li>
                                  <li><StyledLiIcon icon={faDotCircle} /> Een stagevergoeding of marktconform salaris;<br/></li>
                                  <li><StyledLiIcon icon={faDotCircle} /> Laptop;</li><br/>
                                  <li><StyledLiIcon icon={faDotCircle} /> Contractduur en uren in overleg;</li><br/>
                                  <li><StyledLiIcon icon={faDotCircle} /> Soort dienstverband: parttime of stage.</li>
                      </Itemlist>
                    </Paragraph>
                </Vertpad>
                
            </Containerpad>
        </Maincontent>
    <Padding/>
  </Layout> 
)

export default StageCustomerServiceAgent


